import type { ItemModel, DataObjectEvents, DataObject } from 'o365-dataobject';
import { onUnmounted } from 'vue';

// Event types mapping
type DOMEventMapDefinitions = [
    [HTMLElement, HTMLElementEventMap],
    [Document, DocumentEventMap],
    [Window, WindowEventMap],
    [FileReader, FileReaderEventMap],
    [Element, ElementEventMap],
    [Animation, AnimationEventMap],
    [EventSource, EventSourceEventMap],
    [AbortSignal, AbortSignalEventMap],
    [AbstractWorker, AbstractWorkerEventMap]
];
type DOMEventSubscriber = DOMEventMapDefinitions[number][0];

type MapDefinitionToEventMap<D extends { [K: number]: any[] }, T> = { [K in keyof D]: D[K] extends any[] ? (T extends D[K][0] ? D[K][1] : never) : never };
type GetDOMEventMaps<T extends DOMEventSubscriber> = MapDefinitionToEventMap<DOMEventMapDefinitions, T>;

type MapEventMapsToKeys<D extends { [K: number]: any }> = { [K in keyof D]: D[K] extends never ? never : keyof D[K] };
type MapEventMapsToEvent<D extends { [K: number]: any }, T extends PropertyKey> = { [K in keyof D]: D[K] extends never ? never : (T extends keyof D[K] ? D[K][T] : never) };

/**
 * Helper function for adding an event listener in vue component with automatic unmounted handling
 */
export default function useEventListener<T extends DOMEventSubscriber, K extends MapEventMapsToKeys<GetDOMEventMaps<T>>[number] & string>(pTarget: T, pEvent: K, pListener: (this: T, ev: (MapEventMapsToEvent<GetDOMEventMaps<T>, K>[number])) => any, pOptions?: AddEventListenerOptions) {

    pTarget.addEventListener(pEvent, pListener as any, pOptions);
    onUnmounted(() => {
        pTarget.removeEventListener(pEvent, pListener as any);
    });
}

/** Utility function for adding an event listener to an element with a cancelation token  */
export function addEventListener<T extends DOMEventSubscriber, K extends MapEventMapsToKeys<GetDOMEventMaps<T>>[number] & string>(pTarget: T, pEvent: K, pListener: (this: T, ev: (MapEventMapsToEvent<GetDOMEventMaps<T>, K>[number])) => any, pOptions?: AddEventListenerOptions) {
    pTarget.addEventListener(pEvent, pListener as any, pOptions);
    return () => pTarget.removeEventListener(pEvent, pListener as any);
}

/** Helper function for adding an event listener to a DataObject in vue component with automatic clean up when unmounting */
export function useDataObjectEventListener<T extends keyof DataObjectEvents<IT>, IT extends ItemModel = ItemModel>(pDataObject: DataObject<IT>, pEvent: T, pListener: DataObjectEvents<IT>[T], pOptions?: { immediate: boolean}) {

    const cancelEvent = pDataObject.on(pEvent, pListener);

    if (pOptions?.immediate) {
        switch (pEvent) {
            case 'DataLoaded':
                if (pDataObject.state.isLoaded) {
                    (pListener as any)(pDataObject.data);
                }
            break;
        }
    }

    onUnmounted(() => {
        cancelEvent();
    });
}

/** Helper function for safelly executing a cancel token on vue component unmount. Can be used with any EventEmitter events */
export function useCancelToken(pCancelToken: () => void) {
    let cancelToken: null | (() => void) = pCancelToken;
    onUnmounted(() => {
        if (cancelToken) {
            cancelToken();
        }
    });
    return () => {
        if (cancelToken) {
            cancelToken();
        }
        cancelToken = null;
    };
}